import { getAuthToken } from "./authToken";
import { pushEventLogAction, uploadCroppedImage } from "../Redux/Actions";
import store from "../Redux/Store";
import moment from "moment";
import { DEFAULT_IMAGE_CDN, KYC_STATUS, PREMIUM_PURCHASE_TYPE_CONSTANTS, STORE_CUSTOMIZATION } from "../Constants";
import { DOMAIN_STATUS, DOMAIN_TYPE, STORE_DOMAIN_STATUS } from "../Constants/domain";
import { ToastNotifyError, ToastNotifySuccess, ToastNotifyInfo } from "../Components/Toast";
import { ECOMM, ECOMM_PLUS, ECOMM_ELITE } from "../Constants/subscription";
import { formatDate } from "./timeFormat";
import { FilterList, SortAndFilterObject } from "../Containers/Catalog/SortAndFilterModal";
import { MAX_DEFAULT_FILTER_TYPE } from "../Components/CustomDeliveryBox/constants";
import { ObjectType, SubscriptionType } from "../Types";
import {
  ADV_SEO_META_DESCRIPTION_CONSTANTS_PER_BUSINESS,
  GOOGLE_SHOP_PRODUCT_STATUS,
  INITIAL_ROUTE_FOR_WEBVIEW_DASHY,
  PROMOTIONAL_TEXT,
  QUERY_PARAM_AUTH,
  ANNOUNCEMENT_BAR_CATEGORY_TYPES,
  ANNOUNCMENT_BAR_CONFIG_STATUS,
  DEFAULT_APP_VERSION,
  TOAST_TYPE,
  ADDRESS_FLAGS,
} from "./constants";
import { resizedataURL } from "./canvasUtils";
import { createCustomUrl } from "../Containers/DomainSalesSearch/utils/createCustomUrl";
import { DOMAIN_CART_PAGE, DOMAIN_PURCHASE_SUCCESS, DOMAIN_SEARCH_PAGE } from "../Constants/routesConstant";

declare const clevertap: any;

const storeData = store.getState().storeReducer.store;

/* This function uploads and image to S3 and then returns its URL. */
export const uploadImageToS3AndGetLink = (name: string, image: any) => {
  return new Promise((resolve, reject) => {
    const formData = new FormData();
    formData.append("media_type", "additional_pages");
    formData.append("media", image, "image.jpeg");
    store.dispatch(
      uploadCroppedImage(formData, (imageUrl: any) => {
        if (imageUrl) {
          image.src = imageUrl;
          resolve(imageUrl);
        } else {
          reject();
        }
      }),
    );
  });
};

/** function to upload image to s3 and get it's URL */
export const uploadImageAndGetLink = (mediaType, imageFile: any, imageFileName?: string) => {
  return new Promise((resolve, reject) => {
    const formData = new FormData();
    formData.append("media_type", mediaType);
    formData.append("media", imageFile, imageFileName || imageFile?.name);
    store.dispatch(
      uploadCroppedImage(formData, (imageUrl: any) => {
        if (imageUrl) {
          imageFile.src = imageUrl;
          resolve(imageUrl);
        } else {
          reject();
        }
      }),
    );
  });
};

/* This function compressed and uploads image to S3 and then returns its URL. */
export const uploadCompressedImageToS3AndGetLink = (name: string, image: any, imageCategory: string) => {
  return new Promise((resolve, reject) => {
    readFile(image).then((imageDataUrl: any) => {
      resizedataURL(imageDataUrl, 0, 0).then((imageUrl: any) => {
        const formData = new FormData();
        formData.append("image_category", imageCategory);
        formData.append("media_type", imageCategory);
        formData.append("media", imageUrl, name);
        store.dispatch(
          uploadCroppedImage(formData, (imageUrl: any) => {
            if (imageUrl) {
              image.src = imageUrl; // not aware of why this was used here (originally). therefore leaving this line untouched.
              resolve(imageUrl);
            } else {
              reject();
            }
          }),
        );
      });
    });
  });
};

/* This function returns the subscription type of a merchant. */
export const checkMerchantSubscriptionType = (subscriptionType: SubscriptionType) => {
  const userSubscriptionDetails = store.getState().userReducer.userSubscriptionDetails;
  const isPlus = userSubscriptionDetails?.subscription_type === ECOMM_PLUS,
    isElite = userSubscriptionDetails?.subscription_type === ECOMM_ELITE,
    isEssential = userSubscriptionDetails?.subscription_type === ECOMM;

  switch (subscriptionType) {
    case ECOMM_ELITE:
      return isElite;
    case ECOMM_PLUS:
      return isPlus || isElite;
    case ECOMM:
      return isEssential || isPlus || isElite;
    default:
      return false;
  }
};

/**
 * funciton returns the prmium payment
 * type from the text we are storing in
 * session at the time of the init payment.
 * @param {String} text
 */
export const getPurchaseTypeFromText = (text: string | null) => {
  switch (text) {
    case PREMIUM_PURCHASE_TYPE_CONSTANTS.THEME:
      return 1;
    case PREMIUM_PURCHASE_TYPE_CONSTANTS.DOMAIN:
    case PREMIUM_PURCHASE_TYPE_CONSTANTS.DOMAIN_SUBSCRIPTION:
      return 2;
    case PREMIUM_PURCHASE_TYPE_CONSTANTS.DOMAIN_MARKETING:
      return 2;
    case PREMIUM_PURCHASE_TYPE_CONSTANTS.DOMAIN_CONNECT:
      return 3;
    case PREMIUM_PURCHASE_TYPE_CONSTANTS.GOOGLE_ADDS:
      return 4;
    case PREMIUM_PURCHASE_TYPE_CONSTANTS.SUSBSCRIPTION:
      return 5;
    case PREMIUM_PURCHASE_TYPE_CONSTANTS.WORKSPACE:
      return 8;
    case PREMIUM_PURCHASE_TYPE_CONSTANTS.CREDIT_ENGINE:
      return 9;
    case PREMIUM_PURCHASE_TYPE_CONSTANTS.SMS_CAMPAIGN:
      return 11;
    case PREMIUM_PURCHASE_TYPE_CONSTANTS.WABA_PURCHASE:
      return 14;
    case PREMIUM_PURCHASE_TYPE_CONSTANTS.PURCHASE_BRAND_WEBSITE_THEME:
      return 15;
    // This is given a random number as it has no type stored in backend
    case PREMIUM_PURCHASE_TYPE_CONSTANTS.SUBSCRIPTION_USING_COUPONS:
      return 100;
  }
};

/**
 * Helper function for addition of the variant product
 * item to the cart for update the cart
 * @param {string} type
 * @param {Object} item
 * @param {Array} cartItems
 * @returns
 */
export const mutateVariantItemWithCartData = (
  type: any,
  item: any,
  variant: { variant_id: any; discounted_price: number | string },
  cartItems: any,
): any => {
  try {
    const isB2bStore = isStoreTypeB2b();
    const tempCart = [...JSON.parse(JSON.stringify(cartItems))];
    let idx;

    if (isB2bStore) {
      item.discounted_price = "";
      item.price = "";
      variant.discounted_price = "";
    }
    // create important keys in the Item
    item = {
      ...item,
      item_name: item.name,
      item_quantity: item.item_quantity ? item.item_quantity : "1",
      item_price: item.price,
      unit_price: item.discounted_price,
      discounted_price: item.discounted_price,
      item_id: item.id,
      item_type: "catalog",
    };

    switch (type) {
      // case for increase in item quantity
      case "add":
        idx = tempCart.findIndex((ele) => ele.item_id === item.item_id);

        if (tempCart[idx] && tempCart[idx].variants_selected) {
          const variantIdx = tempCart[idx].variants_selected.findIndex(
            (el: { variant_id: any }) => el.variant_id === variant.variant_id,
          );

          /** check if the variant already exist */
          if (tempCart[idx].variants_selected.length > 0 && variantIdx !== -1) {
            // Increase the variant quantity by one
            tempCart[idx].variants_selected[variantIdx].quantity =
              Number(tempCart[idx].variants_selected[variantIdx].quantity) + 1;

            // calculate amount of variant selected
            // amount -> quantity * discounted_price
            tempCart[idx].variants_selected[variantIdx].amount = isB2bStore
              ? 0
              : Number(tempCart[idx].variants_selected[variantIdx].quantity) *
                tempCart[idx].variants_selected[variantIdx].discounted_price;
          } else {
            // add new variant to the selected variant list
            tempCart[idx].variants_selected.push({ ...variant, quantity: 1, amount: variant.discounted_price });
          }
          /** Increase the whole item quantity by one */
          tempCart[idx].item_quantity = Number(tempCart[idx].item_quantity) + 1;
        } else {
          item.variants_selected = [{ ...variant, quantity: 1, amount: variant.discounted_price }];
          tempCart.push(item);
        }
        break;
      case "remove":
        idx = tempCart.findIndex((ele) => ele.item_id === item.item_id);

        if (tempCart[idx].variants_selected) {
          const variantIdx = tempCart[idx].variants_selected.findIndex(
            (el: { variant_id: any }) => el.variant_id === variant.variant_id,
          );

          if (Number(tempCart[idx].variants_selected[variantIdx].quantity) === 1) {
            // Remove the item if its a last one
            tempCart[idx].variants_selected.splice(variantIdx, 1);
            tempCart[idx].item_quantity = Number(tempCart[idx].item_quantity) - 1;
            if (tempCart[idx].variants_selected.length === 0) {
              tempCart.splice(idx, 1);
            }
          } else {
            // Decrease the variant quantity by one
            tempCart[idx].variants_selected[variantIdx].quantity =
              Number(tempCart[idx].variants_selected[variantIdx].quantity) - 1;

            // calculate amount of variant selected
            // amount -> quantity * discounted_price
            tempCart[idx].variants_selected[variantIdx].amount =
              Number(tempCart[idx].variants_selected[variantIdx].quantity) *
              tempCart[idx].variants_selected[variantIdx].discounted_price;

            /** Decrease the whole item quantity by one */
            if (tempCart[idx] && tempCart[idx].length !== 0) {
              tempCart[idx].item_quantity = Number(tempCart[idx].item_quantity) - 1;
            }
          }
        }
        break;
      case "repeat":
        idx = tempCart.findIndex((ele) => ele.item_id === item.item_id);

        if (tempCart[idx].variants_selected && tempCart[idx].variants_selected.length > 0) {
          tempCart[idx].variants_selected[tempCart[idx].variants_selected.length - 1].quantity =
            Number(tempCart[idx].variants_selected[tempCart[idx].variants_selected.length - 1].quantity) + 1;

          // calculate amount of variant selected
          // amount -> quantity * discounted_price
          tempCart[idx].variants_selected[tempCart[idx].variants_selected.length - 1].amount =
            Number(tempCart[idx].variants_selected[tempCart[idx].variants_selected.length - 1].quantity) *
            tempCart[idx].variants_selected[tempCart[idx].variants_selected.length - 1].discounted_price;
          tempCart[idx].item_quantity = Number(tempCart[idx].item_quantity) + 1;
        }
        break;
    }

    return tempCart;
  } catch (err) {
    console.log(err);
    return [];
  }
};

export const getAdStatus = (status: any) => {
  switch (status) {
    case 1:
    case 2:
      return { code: status, text: "Draft", class: "draft" };
    case 3:
    case 4:
    case 5:
      return { code: status, text: "In Review", class: "in-review" };
    case 6:
      return { code: status, text: "Active", class: "active" };
    case 7:
      return { code: status, text: "Paused", class: "paused" };
    case 8:
      return { code: status, text: "Expired", class: "expired" };
  }
};

export const getCompletedStepsListforAd = (formData: {
  ad_info: { heading1: string; heading2: string; heading3: string; description1: string; description2: string };
  ad_keywords_list: string | any[];
  location: { google_location_list: string | any[] };
  budget: { days: any; daily_limit: any };
}) => {
  const tempCompletedSteps = [];

  // First Step
  if (
    formData?.ad_info?.heading1 &&
    formData?.ad_info?.heading2 &&
    formData?.ad_info?.heading3 &&
    formData?.ad_info?.description1 &&
    formData?.ad_info?.description2
  ) {
    tempCompletedSteps.push(1);
  }
  // Second Step
  if (formData?.ad_keywords_list?.length > 0) {
    tempCompletedSteps.push(2);
  }
  // Third Step
  if (formData?.location?.google_location_list?.length > 0) {
    tempCompletedSteps.push(3);
  }
  // Fourth Step
  if (formData?.budget?.days && formData?.budget?.daily_limit) {
    tempCompletedSteps.push(4);
  }

  return tempCompletedSteps;
};

/**
 * This function parse the stringfy data
 * @param data
 */
export function parseToJson(data: any) {
  return JSON.parse(data);
}

/**
 * This function will share data on whatsapp web
 * @param data
 */
export const openWhatsAppWeb = (data: any) => {
  const url = data.phone
    ? "https://api.whatsapp.com/send?phone=+91" + data.phone + "&text="
    : "https://api.whatsapp.com/send?text=";
  window.open(url + encodeURIComponent(data.whatsapp_text), "_blank");
};

export function getEventPlatform() {
  const win = window as any;
  const isDomainMarketingRoute = ["/domain-sale", "/domain-completion", "/domain-open-search"].includes(
    window.location.pathname,
  );
  let platform = !isDomainMarketingRoute ? "WebConsole" : "Website",
    device = "Desktop";
  if (win.Android) {
    platform = "Android";
    device = "Mobile";
  }
  if (win.ReactNativeWebView) {
    platform = "iOS";
    device = "Mobile";
  }
  return { platform, device };
}

export function getAppOS() {
  const urlParams = new URLSearchParams(window.location.search);

  const win = window as any;
  let os = "web_console";
  let device = "desktop";
  let version = DEFAULT_APP_VERSION;

  if (win.Android) {
    os = "android_native";
    device = "mobile";
    version = urlParams.get("app_version") || DEFAULT_APP_VERSION;
  }
  if (win.ReactNativeWebView) {
    os = "ios";
    device = "mobile";
    version = urlParams.get("version_number") || DEFAULT_APP_VERSION;
  }
  return { os, device, version };
}

export const clevertapEventPush = (eventName: string, additionalData: any = {}) => {
  const storeData = store.getState().storeReducer.store;
  const eventPlatforms = getEventPlatform();
  const clevertapPayload = {
    store_id: storeData?.store_id,
    device: eventPlatforms.device,
    platform: eventPlatforms.platform,
    ...additionalData,
  };
  const apiPayload = {
    event_name: eventName,
    data: clevertapPayload,
  };
  store.dispatch(pushEventLogAction(apiPayload));
  clevertap?.event?.push?.(eventName, clevertapPayload);
};

export const hitKycClevertapEvents = () => {
  const kyc_status = store.getState().storeReducer.store.kyc_status;
  switch (kyc_status) {
    case KYC_STATUS.NOT_STARTED:
      break;
    case KYC_STATUS.FAILED:
      break;
  }
};

export function getUrlParamValue(param) {
  const params = new URLSearchParams(window.location.search);
  return params.get(param) || "";
}

export const getOrderTypeText = (val: number): string => {
  switch (val) {
    case 1:
      return "delivery";
    case 2:
      return "pickup";
    case 3:
      return "self billed";
    case 4:
      return "payment link";
    default:
      return "";
  }
};

/**
 * Capitalize input strings
 */

export function capitalizeInputString(text: string) {
  if (typeof text !== "string") {
    return text;
  }
  return `${text.charAt(0).toUpperCase()}${text.substring(1).toLowerCase()}`;
}

export const capitalizeAllWords = (sentence = "") =>
  sentence
    .split(" ")
    .map((text: string) => capitalizeInputString(text))
    .join(" ");

export const hideCTAWhenRedirectedFromSettings = ({
  versionNumber,
  app_version_code,
  redirectedFromSettings,
}: any): boolean => {
  return !((versionNumber || app_version_code) && redirectedFromSettings);
};

export const openedInMobile = (): boolean => {
  const urlParams = new URLSearchParams(window.location.search);
  const versionNumber = JSON.parse(urlParams.get("version_number") as any);
  const app_version_code = JSON.parse(urlParams.get("app_version_code") as any);
  return !!(versionNumber || app_version_code);
};

export const getLoginUrl = () => {
  if (window.location.href.includes("utm_source")) {
    const utm_source = window.location.href.split("utm_source")[1];
    return `/?utm_source${utm_source}`;
  } else {
    return "/";
  }
};

export const getFirstYearSubscriptionPrices = (type: string) => {
  const subscriptionPricingList = store.getState().subscriptionReducer.subscriptionPricingList;
  return subscriptionPricingList?.[type]?.find?.((item: any) => item.year === 1);
};

export const getAmountString = (amount: number) => {
  if (!amount) {
    return "";
  }
  return amount.toLocaleString("en-US", {
    style: "currency",
    currency: "INR",
  });
};

export const scrolltoSection = (elementId: string) => {
  const element: any = document.getElementById(elementId);
  element?.scrollIntoView();
};

export const showWorkspaceBuytimerBanner = (expiry_time: string) => {
  const time = new Date();
  return expiry_time && moment(time).isBefore(moment(expiry_time));
};

export const getDaysDiffFromToday = (date: any): number => {
  const days = moment.utc(date).startOf("day").diff(moment.utc().startOf("day"), "days");
  return days;
};

interface ICalculateRemainingTime {
  remainingDays: number;
  remainingSeconds: number;
  remainingHours: number;
  remainingYears: number;
  remainingMinutes: number;
}
export const calculateRemainingTime = (date: string, ignoreFormattedTime = true): ICalculateRemainingTime => {
  // const remainingSecond_2 = moment.utc(date).startOf("second").diff(moment().startOf("second"), "seconds");
  const formattedDate = formatDate(date, "YYYY/MM/DD");
  const dateArgument = ignoreFormattedTime ? formattedDate : date;
  const durationDate = moment.duration(moment(dateArgument).diff(moment()));
  const durationTime = moment.duration(moment(date).diff(moment()));
  return {
    remainingDays: Math.ceil(durationDate.asDays()),
    remainingSeconds: durationTime.asSeconds(),
    remainingHours: durationTime.asHours(),
    remainingMinutes: durationTime.asMinutes(),
    remainingYears: Math.round(durationDate.asYears()),
  };
};

/** 15 days after start of expiry month, and the 15 day grace period post expiry */
export const isDomainBannerActive = () => {
  return (
    storeData?.domain_info?.domain_type === DOMAIN_TYPE.PURCHASED &&
    storeData?.domain_info?.status === STORE_DOMAIN_STATUS.ACTIVE &&
    getDaysDiffFromToday(storeData?.domain_info?.expiry_date) <= 15 &&
    getDaysDiffFromToday(storeData?.domain_info?.expiry_date) >=
      storeData?.constants?.domain_renewal_grace_period_in_days * -1
  );
};

export const discountTimer = (time: any) => {
  const now = moment();
  const end = moment(time);
  const duration = moment.duration(end.diff(now));

  const days = Math.floor(duration.asDays());
  duration.subtract(moment.duration(days, "days"));

  // Get hours and subtract from duration
  const hours = Math.floor(duration.hours());
  duration.subtract(moment.duration(hours, "hours"));

  // Get Minutes and subtract from duration
  const minutes = Math.floor(duration.minutes());
  duration.subtract(moment.duration(minutes, "minutes"));

  // Get seconds
  const seconds = Math.floor(duration.seconds());

  return {
    day: days > 0 ? days : "00",
    hour: hours > 0 ? hours : "00",
    minute: minutes,
    second: seconds,
  };
};

export const storeLiveWhatsappMessage = (store_name: string, domain_name: string) => {
  window.open(
    `https://api.whatsapp.com/send?text=${store_name}%20is%20now%20Online%20%F0%9F%8F%AA%0AOrder%2024x7%20-%20Click%20on%20the%20link%20to%20place%20an%20order%0A%0A${domain_name}%0A%0APay%20using%20Gpay%2C%20Paytm%2C%20Phonepe%20and%20150%20%20UPI%20Apps%20or%20Cash`,
  );
};

/**
 * Check if the website is opened
 * on desktop or mobile
 * @returns {boolean} returns true for desktop and false for mobile
 */
export const isDesktop = (): boolean => window.screen.width >= 768;

/**
 * Pass the search params keys that you want to fetch in an array
 * It will return an object with the key-value pair
 * @param {array} arrayOfParamKeys []
 * @return {object} destructure the object in the same order as their respective keys
 */
export function getUrlParams(arrayOfParamKeys: string[]): Record<string, any> {
  const urlParams = new URLSearchParams(window.location.search);
  const urlData: Record<string, any> = {};
  for (const key of arrayOfParamKeys) {
    urlData[key] = urlParams.get(key);
  }
  return urlData;
}

export const isKycVerificationInitiated = (kycStatus: number, showError = true): boolean => {
  const isInitiated = [(KYC_STATUS.COMPLETED, KYC_STATUS.INITIATED)].includes(kycStatus);
  if (isInitiated && showError) {
    ToastNotifyError("Your KYC is in progress, Wait for sometime");
  }
  return isInitiated;
};

export function isStoreB2c() {
  return getStoreType() === STORE_CUSTOMIZATION.ORDER_BASED;
}

export function isStoreServiceBased() {
  return getStoreType() !== STORE_CUSTOMIZATION.ORDER_BASED && getStoreType() !== STORE_CUSTOMIZATION.B2B;
}

export function isStoreTypeB2b() {
  return getStoreType() === STORE_CUSTOMIZATION.B2B;
}

export async function downloadImageFromURL(imageUrl: string, downloadImageFileName: string) {
  const image = await fetch(imageUrl);
  const imageBlog = await image.blob();
  const imageURL = URL.createObjectURL(imageBlog);
  const link = document.createElement("a");
  link.href = imageURL;
  link.download = downloadImageFileName;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
}

export function handlePDFDownload(pdfUrl: string, pdfName: string) {
  // using Java Script method to get PDF file
  fetch(pdfUrl).then((response) => {
    response.blob().then((blob) => {
      // Creating new object of PDF file
      const fileURL = window.URL.createObjectURL(blob);
      // Setting various property values
      const alink = document.createElement("a");
      alink.href = fileURL;
      alink.download = pdfName;
      alink.click();
    });
  });
}

export function checkForNumbers(str: string) {
  const numbers = /^[-+]?[0-9]+$/;
  return str.match(numbers);
}
export const getYearString = (year: number) => `${year || "_"} ${year > 1 ? "years" : "year"}`;

/**
 * function to check whether store has address
 * fields added or not
 * returns true if all required fields is working
 * @param storeData
 * @returns boolean
 */
export const ifStoreDataAddressExist = (storeData: {
  address: { address_1: string; address_2: string; pincode: string };
}) => {
  return (storeData?.address?.address_1 || storeData?.address?.address_2) && storeData?.address?.pincode;
};

/**
 * Lets web applications asynchronously read the contents of files (or raw data buffers)
 * stored on the user's computer, using File or Blob objects to specify the file or data to read.
 * returns base64 image format of any image file
 * @param file
 * @returns string
 */
export function readFile(file: any) {
  return new Promise((resolve) => {
    const reader = new FileReader();
    reader.addEventListener("load", () => resolve(reader.result), false);
    reader.readAsDataURL(file);
  });
}

/**
 * function to create a statick link for the YT thumbnail from
 * the YT url user provides
 * @param url string
 * @returns string
 */
export function youTubeGetID(url: any) {
  url = url.split(/(vi\/|v=|\/v\/|youtu\.be\/|\/embed\/)/);
  return url[2] !== undefined ? url[2].split(/[^0-9a-z_-]/i)[0] : url[0];
}

/**
 * Returns the given `obj` without the `property`.
 *
 * @param {ObjectType} obj
 * @param {String} property
 *
 * @returns {object} rest of the object without the designated key
 */
export function withoutProperty(obj: any, property: string): any {
  const { [property]: unused, ...rest } = obj;

  return rest;
}

/**
 * get array of objects with unique elements
 * based on key provided
 * @param arr
 * @param key
 * @returns
 */
export function getUniqueListBy(arr: any[], key: string) {
  try {
    if (key) {
      return [...new Map(arr.map((item) => [item[key], item])).values()];
    } else {
      return arr;
    }
  } catch (err) {
    return arr;
  }
}

export function getSortAndFiltersList(allFilters: SortAndFilterObject[]): FilterList {
  const defaultFilters: SortAndFilterObject[] = [];
  const customFilters: SortAndFilterObject[] = [];
  !!allFilters?.length &&
    allFilters.forEach((item: SortAndFilterObject) => {
      item?.type > MAX_DEFAULT_FILTER_TYPE ? customFilters.push(item) : defaultFilters.push(item);
    });
  const sortAndFiltersList = { defaultFilters: defaultFilters, customFilters: customFilters };
  return sortAndFiltersList;
}

export function getGenderNameFromStatus(status: number): string {
  switch (status) {
    case 1:
      return "Male";
    case 2:
      return "Female";
    case 3:
      return "Prefer not to say";
    default:
      return "";
  }
}

const convertSnakeToCamel = (str: string) =>
  str.toLowerCase().replace(/[_][a-z0-9]/g, (group) => group.slice(-1).toUpperCase());

export function transformBEDataToFE(data: any, convertNestedKeys = true) {
  const isDataArrayType = Array.isArray(data);
  const isDataObjectType = data?.constructor === Object;
  const transformedData: any = isDataArrayType ? [] : isDataObjectType ? {} : data;
  if (isDataArrayType) {
    for (const item of data) {
      transformedData.push(transformBEDataToFE(item));
    }
  }
  if (isDataObjectType) {
    for (const key in data) {
      const transformedKey = convertSnakeToCamel(key);
      const value = data[key];
      if (convertNestedKeys && typeof value === "object") {
        transformedData[transformedKey] = transformBEDataToFE(value);
      } else {
        transformedData[transformedKey] = value;
      }
    }
  }
  return transformedData;
}

/**
 * function to explicitly convert string type to number
 * @param data - which is of string type
 * @returns converted value
 */
export const convertStringToNumber = (data: any) => {
  if (typeof data !== "string") {
    return data;
  }
  return Number(data);
};

export function checkIsWebview(): boolean {
  return location.pathname.includes("webview");
}

export function getHybridRoute(url: string, additionalParamsString = ""): string {
  const token = getAuthToken();
  const storeid = getStoreId();
  const urlParams = new URLSearchParams(window.location.search);
  const app_version = urlParams.get("app_version");
  const app_version_code = urlParams.get("app_version_code");
  const upi_options = urlParams.get("upi_options");
  const isDashy = !location.pathname.includes("webview");
  return (
    (isDashy
      ? url
      : `${INITIAL_ROUTE_FOR_WEBVIEW_DASHY}${url}?token=${token}&storeid=${storeid}&app_version=${app_version}&app_version_code=${app_version_code}&upi_options=${upi_options}`) +
    (additionalParamsString ? (isDashy ? "?" : "&") + additionalParamsString : "")
  );
}

export function getStoreId(): number {
  return store?.getState()?.storeReducer?.store?.store_id;
}

export function saveGshopCatalogUpdatedList(data: ObjectType): ObjectType {
  const { products_statuses } = data;
  const updatedProductStatus = { ...products_statuses };
  if (Object.keys(products_statuses)?.length) {
    updatedProductStatus[GOOGLE_SHOP_PRODUCT_STATUS.ALL.productType] = [];
    Object.keys(products_statuses).forEach((key) => {
      updatedProductStatus[GOOGLE_SHOP_PRODUCT_STATUS.ALL.productType] = updatedProductStatus[
        GOOGLE_SHOP_PRODUCT_STATUS.ALL.productType
      ].concat(products_statuses[key]);
    });
  }
  return updatedProductStatus;
}

export function getStoreType() {
  const storeData = store.getState().storeReducer.store;
  return storeData?.services?.store_type_flag;
}

export function getOrderMode(order: any) {
  return order?.order_mode;
}

export function googleShopBase64EncodedUrl(url: string): string {
  return url.split(",")?.[1] ?? "";
}

export function handleMarketingBack(): void {
  const win = window as any;
  if (win.ReactNativeWebView) {
    win.ReactNativeWebView.postMessage(JSON.stringify({ redirectMarketingPage: true }));
  }
  if (win.Android) {
    win.Android.sendData(JSON.stringify({ redirectMarketingPage: true }));
  }
}

export function isOrderPartialPaymentType(): boolean {
  const orderData = store.getState().ordersReducer.orderDetails?.data;
  if (orderData && orderData?.partial_payment_type > 0 && orderData?.part_payment_info) {
    return true;
  } else {
    return false;
  }
}

const updateCartVariantItems = (variants_selected, fetchedItemVariants, fetchedItem) => {
  const modifiedVariantsSelected = variants_selected.map((b2bCartItemVariant) => {
    const selectedVariant = fetchedItemVariants.find(
      (fetchedItemVariant) => fetchedItemVariant.variant_id === b2bCartItemVariant.variant_id,
    );
    b2bCartItemVariant.available = selectedVariant.available;
    b2bCartItemVariant.available_quantity = selectedVariant.available_quantity;
    b2bCartItemVariant.thumbnail_url =
      Array.isArray(selectedVariant.images) && selectedVariant.images[0].thumbnail_url
        ? selectedVariant.images[0].thumbnail_url
        : fetchedItem?.thumbnail_url || DEFAULT_IMAGE_CDN;
    return b2bCartItemVariant;
  });
  return modifiedVariantsSelected;
};

export const updateB2bCartItemsDetailsWithFetchedItemDetails = (b2bCartItems, fetchedItems) => {
  b2bCartItems.forEach((b2bCartItem) => {
    const fetchedItem = fetchedItems.find((item) => item.id === b2bCartItem.id);
    b2bCartItem.available = fetchedItem.available;
    b2bCartItem.available_quantity = fetchedItem.available_quantity;
    if (Array.isArray(b2bCartItem.variants_selected) && !!b2bCartItem.variants_selected?.length) {
      const modifiedVariantsSelected = updateCartVariantItems(
        b2bCartItem.variants_selected,
        fetchedItem.variants,
        fetchedItem,
      );
      b2bCartItem.variants_selected = modifiedVariantsSelected;
    }
  });
  return b2bCartItems;
};

/**
 * @param {object} storeInfo
 * @param {object} seoDetails
 * @return {string}
 */
export const getMetaDescription = () => {
  const storeInfo = store.getState().storeReducer.store;
  const businessTypes = storeInfo.store_businesses;
  const isAddresVisible = storeInfo?.address?.visibility === ADDRESS_FLAGS.VISIBLE;
  let match = 13;
  let description = "";

  // default
  if (businessTypes && businessTypes.length > 1) {
    match = ADV_SEO_META_DESCRIPTION_CONSTANTS_PER_BUSINESS.DEFAULT;
  } else if (businessTypes && businessTypes.length === 1 && businessTypes[0].business_id === 8) {
    match = ADV_SEO_META_DESCRIPTION_CONSTANTS_PER_BUSINESS.DEFAULT;
  } else if (businessTypes && businessTypes.length === 0) {
    match = ADV_SEO_META_DESCRIPTION_CONSTANTS_PER_BUSINESS.DEFAULT;
  }

  // Genral store
  if (businessTypes && businessTypes.length === 1 && businessTypes[0].business_id === 1) {
    match = ADV_SEO_META_DESCRIPTION_CONSTANTS_PER_BUSINESS.GENERAL_STORE;
  }
  // Pharmacy & Wellness
  if (businessTypes && businessTypes.length === 1 && businessTypes[0].business_id === 2) {
    match = ADV_SEO_META_DESCRIPTION_CONSTANTS_PER_BUSINESS.PHARMACY_WELLNESS;
  }
  // Mobile & Electronics
  if (businessTypes && businessTypes.length === 1 && businessTypes[0].business_id === 3) {
    match = ADV_SEO_META_DESCRIPTION_CONSTANTS_PER_BUSINESS.MOBILE_ELECTRONICS;
  }
  // Clothing & Fashion
  if (businessTypes && businessTypes.length === 1 && businessTypes[0].business_id === 4) {
    match = ADV_SEO_META_DESCRIPTION_CONSTANTS_PER_BUSINESS.CLOTHING_FASHION;
  }
  // Resturants, Food Joints & Bakery
  if (businessTypes && businessTypes.length === 1 && businessTypes[0].business_id === 5) {
    match = ADV_SEO_META_DESCRIPTION_CONSTANTS_PER_BUSINESS.RESTAURANTS_FOOD_JOINTS_BAKERIES;
  }
  // Stationary & Office Supplies
  if (businessTypes && businessTypes.length === 1 && businessTypes[0].business_id === 6) {
    match = ADV_SEO_META_DESCRIPTION_CONSTANTS_PER_BUSINESS.STATIONARY_OFFICE_SUPPLIES;
  }
  // Arts, Jewellery & Collections
  if (businessTypes && businessTypes.length === 1 && businessTypes[0].business_id === 7) {
    match = ADV_SEO_META_DESCRIPTION_CONSTANTS_PER_BUSINESS.ART_JEWELERRY_COLLECTION;
  }
  // Fruits & Vegetables
  if (businessTypes && businessTypes.length === 1 && businessTypes[0].business_id === 13) {
    match = ADV_SEO_META_DESCRIPTION_CONSTANTS_PER_BUSINESS.FRUITS_VEGETABLES;
  }
  // Furniture & Home Decor
  if (businessTypes && businessTypes.length === 1 && businessTypes[0].business_id === 14) {
    match = ADV_SEO_META_DESCRIPTION_CONSTANTS_PER_BUSINESS.FURNITURE_HOME_DECOR;
  }
  // Beauty & Cosmetics
  if (businessTypes && businessTypes.length === 1 && businessTypes[0].business_id === 15) {
    match = ADV_SEO_META_DESCRIPTION_CONSTANTS_PER_BUSINESS.BEAUTY_COSMETICS;
  }

  if (businessTypes && businessTypes.length === 1 && businessTypes[0].business_id === 16) {
    match = ADV_SEO_META_DESCRIPTION_CONSTANTS_PER_BUSINESS.REAL_ESTATE;
  }

  switch (match) {
    case 1:
      description = `Buy and Order online from ${storeInfo.store_info?.name}${
        isAddresVisible && storeInfo.address?.city ? `,${storeInfo.address?.city} ` : ""
      }| Online Grocery & Dairy Store - ${`${storeInfo.store_info?.name}${
        isAddresVisible && storeInfo.address?.city ? `,${storeInfo.address?.city}` : ""
      }`}. Order Now!`;
      break;
    case 2:
      description = `Buy and Order online from  ${storeInfo.store_info?.name}${
        isAddresVisible && storeInfo.address?.city ? `,${storeInfo.address?.city} ` : ""
      }| Online Pharmacy & Wellness Store - ${`${storeInfo.store_info?.name}${
        isAddresVisible && storeInfo.address?.city ? `,${storeInfo.address?.city}` : ""
      }`}. Order Now!`;
      break;
    case 3:
      description = `Buy and Order online from ${storeInfo.store_info?.name}${
        isAddresVisible && storeInfo.address?.city ? `,${storeInfo.address?.city} ` : ""
      }| Online Mobile & Electronics Store - ${`${storeInfo.store_info?.name}${
        isAddresVisible && storeInfo.address?.city ? `,${storeInfo.address?.city}` : ""
      }`}. Order Now!`;
      break;
    case 4:
      description = `Buy and Order online from ${storeInfo.store_info?.name}${
        isAddresVisible && storeInfo.address?.city ? `,${storeInfo.address?.city} ` : ""
      }| Online Fashion Store - ${`${storeInfo.store_info?.name}${
        isAddresVisible && storeInfo.address?.city ? `,${storeInfo.address?.city}` : ""
      }`}. Order Now!`;
      break;
    case 5:
      description = `Order Food online from ${storeInfo.store_info?.name}${
        isAddresVisible && storeInfo.address?.city ? `,${storeInfo.address?.city} ` : ""
      }|  Now order online & pay using UPI, Paytm, GooglePay, PhonePe at ${storeInfo.store_info?.name}${
        isAddresVisible && storeInfo.address?.city ? `,${storeInfo.address?.city}` : ``
      } . Order Now!`;
      break;
    case 6:
      description = `Buy and Order online from ${storeInfo.store_info?.name}${
        isAddresVisible && storeInfo.address?.city ? `,${storeInfo.address?.city} ` : ""
      }| Online Stationary Store - ${storeInfo.store_info?.name}${
        isAddresVisible && storeInfo.address?.city ? `,${storeInfo.address?.city}` : ``
      }. Order Now!`;
      break;
    case 7:
      description = `Buy and Order online from ${storeInfo.store_info?.name}${
        isAddresVisible && storeInfo.address?.city ? `,${storeInfo.address?.city} ` : ""
      }| Online Arts, Jewellery & Collections Store - ${storeInfo.store_info?.name}${
        isAddresVisible && storeInfo.address?.city ? `,${storeInfo.address?.city}` : ``
      }. Order Now!`;
      break;
    case 8:
      description = `Buy and Order online from ${storeInfo.store_info?.name}${
        isAddresVisible && storeInfo.address?.city ? `,${storeInfo.address?.city} ` : ""
      }| Online Fruits & Vegetables Store - ${storeInfo.store_info?.name}${
        isAddresVisible && storeInfo.address?.city ? `,${storeInfo.address?.city}` : ``
      }. Order Now!`;
      break;
    case 9:
      description = `Buy and Order online from ${storeInfo.store_info?.name}${
        isAddresVisible && storeInfo.address?.city ? `,${storeInfo.address?.city} ` : ""
      }| Online Furniture & Home Decor Store - ${storeInfo.store_info?.name}${
        isAddresVisible && storeInfo.address?.city ? `,${storeInfo.address?.city}` : ``
      }. Order Now!`;
      break;
    case 10:
      description = `Buy and Order online from ${storeInfo.store_info?.name}${
        isAddresVisible && storeInfo.address?.city ? `,${storeInfo.address?.city} ` : ""
      }| Online Beauty & Cosmetics Store - ${storeInfo.store_info?.name}${
        isAddresVisible && storeInfo.address?.city ? `,${storeInfo.address?.city}` : ``
      }. Order Now!`;
      break;
    case 11:
      description = `Buy and Order online from ${storeInfo.store_info?.name}${
        isAddresVisible && storeInfo.address?.city ? `,${storeInfo.address?.city} ` : ""
      }| Now order online & pay using UPI, Paytm, GooglePay, PhonePe at ${storeInfo.store_info?.name}${
        isAddresVisible && storeInfo.address?.city ? `,${storeInfo.address?.city}` : ``
      }. Order Now!`;
      break;
    case 12:
      description = `“Buy / Sell / Rent your real estate dream property from ${storeInfo.store_info?.name} ${
        isAddresVisible && storeInfo.address?.city ? `,${storeInfo.address?.city} ` : ""
      } | Real Estate Property ${storeInfo.store_info?.name}. Explore Now!”`;
      break;
    default:
      description = `Buy and Order online from ${storeInfo.store_info?.name}${
        isAddresVisible && storeInfo.address?.city ? `,${storeInfo.address?.city} ` : ""
      }| Now order online & pay using UPI, Paytm, GooglePay, PhonePe at ${storeInfo.store_info?.name}${
        isAddresVisible && storeInfo.address?.city ? `,${storeInfo.address?.city}` : ``
      }. Order Now!`;
      break;
  }

  return description;
};

const getMetaTitle = () => {
  const storeInfo = store.getState().storeReducer.store;
  const businessTypes = storeInfo.store_businesses;
  return `${storeInfo?.store_info?.name}${
    storeInfo?.address?.visibility === ADDRESS_FLAGS.VISIBLE && storeInfo?.address?.city
      ? `, ${storeInfo.address?.city}`
      : ""
  } - ${
    businessTypes && businessTypes.length === 1 && businessTypes[0].business_id === 16
      ? "Real Estate Property"
      : "Order Online"
  }`;
};

const generateSeoData = () => {
  return {
    title: getMetaTitle(),
    description: getMetaDescription(),
    ogTitle: getMetaTitle(),
    ogDescription: getMetaDescription(),
  };
};

export function getAdvancedCustomSeoData(): any {
  const advanceCustomSeoData = store.getState().seoToolsReducer.advCustomSeoDetails;
  if (advanceCustomSeoData) {
    const { title, og_title, description, og_description } = advanceCustomSeoData;
    return { title: title.value, ogTitle: og_title.value, description, ogDescription: og_description };
  } else {
    return generateSeoData();
  }
}

export const generateDefaultProductSeoData = (productData) => {
  const storeInfo = store.getState().storeReducer.store;
  const storeName = storeInfo?.store_info?.name;
  const storeUrl = storeInfo?.domain_info?.domain;
  return {
    title: {
      value: `Buy ${productData?.name} online at best price  | ${storeName}`,
    },
    description: `Buy ${productData?.name} online at the best price in India on ${storeUrl}.`,
    og_title: {
      value: getMetaTitle(),
    },
    og_description: getMetaDescription(),
  };
};

/**
 *
 * @param str
 * @param chars
 * @param placeholder
 * @returns Truncated string with ellipsis at end, and completing the last word.
 */
export const truncateAfterWord = (str: string, chars: number, placeholder = "…"): string => {
  if (str.length <= chars) {
    return str;
  }
  const lastSpaceIndex = str.substring(0, chars - placeholder.length).lastIndexOf(" ");
  const truncatedString = str.substring(0, lastSpaceIndex);
  return `${truncatedString}${placeholder}`;
};

export function isMacOs() {
  if (navigator.userAgent.indexOf("Mac OS X") != -1) {
    return true;
  }

  return false;
}

export function generateRandomId() {
  return "ran-" + Math.random().toString().slice(2);
}

export function getMonthName(monthNumber: number) {
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  return months[monthNumber - 1];
}

export const getCouponIdForSubscriptionPayment = () => {
  const storeData = store.getState().storeReducer.store;
  const couponForSubscriptionPayment = storeData?.coupon_id_expiry?.filter((coupon) => coupon?.is_combo === 0);

  return couponForSubscriptionPayment && couponForSubscriptionPayment?.length > 0
    ? couponForSubscriptionPayment[0]?.coupon_id
    : 0;
};

export const getCouponIdForSubscriptionPaymentWhenBannerActive = () => {
  const storeData = store.getState().storeReducer.store;
  const couponForSubscriptionPayment = storeData?.coupon_id_expiry?.filter((coupon) => coupon?.is_combo === 1);

  return couponForSubscriptionPayment && couponForSubscriptionPayment?.length > 0
    ? couponForSubscriptionPayment[0]?.coupon_id
    : 0;
};

export const getCouponDiscountForSubscriptionPayment = () => {
  const storeState = store?.getState();
  const storeData = storeState?.storeReducer?.store;
  const couponData = storeState?.couponsReducer;
  const couponDiscount =
    (storeData?.coupon_id_expiry && couponData?.subscriptionPurchaseDetailsUsingCoupons?.coupon?.discount_value) || 0;
  return couponDiscount;
};

export function hideWebsiteBuilderFromExistingMerchant() {
  const existingWidgetList = [
    "StaticWidget",
    "CategoryWidget",
    "CategoryProductWidget",
    "AdditionalPageWidget",
    "CollectionWidget",
    "CollectionCategoryProductWidget",
    "SortingAndFilterWidget",
  ];

  const storeData = store.getState().storeReducer.store;
  if (!storeData?.theme) {
    return true;
  }
  const bodyComponents = storeData?.theme?.components?.Body;
  const jsonWidgetData = bodyComponents?.find((component) => component.name === "JsonPageWidget");

  // If JsonPageWidget is not present then check if there exists any widget which is built using API by Operation team.
  if (!jsonWidgetData) {
    const shouldNotShow = bodyComponents?.some((component) => existingWidgetList.includes(component.sub_type));
    return shouldNotShow;
  }

  return false;
}

export function redirectToOrdersPageOnApp() {
  const token = getAuthToken();
  const storeid = getStoreId();
  const urlParams = new URLSearchParams(window.location.search);
  const app_version = urlParams.get("app_version");
  const appRedirectUrl = `/redirect/orders?token=${token}&storeid=${storeid}&app_version=${app_version}&referrer=${encodeURIComponent(
    window.location.href,
  )}`;
  window.location.assign(process.env.REACT_APP_WEBVIEW_URL + appRedirectUrl);
  return;
}

export function containsGmail(input) {
  const regex = /@gmail\.com/i;
  return regex.test(input);
}

export const emailValidator = (emailToValidate) => {
  const emailPattern = /^[\w.-]+@[\w.-]+\.\w+$/;
  const isValidEmail = emailPattern.test(emailToValidate);
  if (isValidEmail) {
    console.log("valid-email");
    return true;
  } else {
    console.log("not-valid-email");
  }
};

export const reloadSrcImage = (id: string, imageItem: any) => {
  (document.getElementById(id) as HTMLImageElement).src = `${imageItem}`;
};

export const verifyCouponDetailsToSendOrNotForNonComboCoupons = (payload, type) => {
  const storeState = store?.getState();
  const storeData = storeState?.storeReducer?.store;
  const subscriptionCouponData = storeState?.couponsReducer?.subscriptionPurchaseDetailsUsingCoupons;

  const payloadType = type === "ecom" ? 5 : 1;
  const payloadYear = payload.year ? payload.year : type === "ecom" ? 1 : -1;

  // Step -1 Check if any coupon exists whose is_combo is 0
  if (storeData?.coupon_id_expiry && subscriptionCouponData?.coupon?.is_combo === 0) {
    // Step -2 : If any such coupon exist, then extract the upgrade, year and subscription_id parameters and compare it with the one coming in payload.
    const currentCouponData = subscriptionCouponData?.cart_items[0];
    return (
      payload?.upgrade === currentCouponData?.upgrade &&
      payloadYear === currentCouponData?.year &&
      currentCouponData?.subscription_id === payloadType
    );
  } else {
    return false;
  }
};

export function getStatusPrefixByOrderMode(orderMode: number): string {
  switch (orderMode) {
    case 1:
      return "Booking";
    case 2:
      return "Booking";
    case 3:
      return "Request";
    default:
      return "Order";
  }
}

export function getSettingsTextPrefixByStoreType(): string {
  const storeType = getStoreType();
  switch (storeType) {
    case 1:
      return "Booking";
    case 2:
      return "Booking";
    case 3:
      return "Request";
    default:
      return "Order";
  }
}

export function showNumericYear(year) {
  const yearArray = year.split("-");
  const numericYear = parseInt(yearArray[0]);
  return numericYear;
}

export function getEmailRedirectLink(email) {
  return `mailto:${email}?subject=Support request for Digital Showroom <Store ID -(${
    storeData.store_id ? storeData.store_id : ""
  })>&body=%28Please do not edit subject line - Digital Showroom %7CStore ID - %3C${
    storeData.store_id
  }%3E%29%0D%0A%0D%0APlease let us know how we can help%3F+%0D%0AStart writing below %F0%9F%91%87`;
}

export function handleEmail(email) {
  window.location.href = getEmailRedirectLink(email);
}

export function showRemainingYears(currentYear, maxRenewalYears, expiry_year) {
  return currentYear + maxRenewalYears - expiry_year;
}

export const redirectToDashy = (route) => {
  window.location.href = createCustomUrl(`${route}?${QUERY_PARAM_AUTH}=${getAuthToken()}`);
};

export function isDomainSearchPage() {
  return window.location.pathname.includes(DOMAIN_SEARCH_PAGE);
}

export function isDomainCartPage() {
  return window.location.pathname.includes(DOMAIN_CART_PAGE);
}

export function isDomainPostPurchase() {
  return window.location.pathname.includes(DOMAIN_PURCHASE_SUCCESS);
}

export const isFalsyExceptZero = (val: any) => (val === 0 ? false : !val);

export const hasSpecialCharsOrEmojis = (str: string) => {
  const specialCharsRegex = /[^\w\s]/g;
  const emojiRegex = /(?:[\u2600-\u27BF]|\([^)]*\))/g as RegExp;

  return specialCharsRegex.test(str) || emojiRegex.test(str);
};

export const hasPromotionalText = (str: string) => {
  // Convert the input string to lowercase
  const lowerCaseStr = str.toLowerCase();

  // Regular expression to match any of the promotional words
  const regex = new RegExp(`\\b(?:${PROMOTIONAL_TEXT.map((word) => word.toLowerCase()).join("|")})\\b`);

  return regex.test(lowerCaseStr);
};

export const hasCapCharInMiddleOfAnyWord = (str: string) => {
  // Regular expression to match any capital letter that is not at the beginning of a word
  const regex = /(?<!\b)[A-Z]/;

  return regex.test(str);
};
export const getDomainSubscriptionInfo = () => {
  const subscriptionDetails = store.getState().themeReducer?.subscriptionDetails;
  const hasExternalDomain = !!subscriptionDetails?.externalDomain?.status;
  const dsDomainInfo = subscriptionDetails?.domain;
  const hasDsDomain = !!dsDomainInfo?.status;
  const daysDifference = hasDsDomain && getDaysDiffFromToday(dsDomainInfo?.expiry);
  const isOutOfGracePeriod = dsDomainInfo?.status === DOMAIN_STATUS.DOMAIN_OUT_OF_GRACE_PERIOD;
  const isExpired = isOutOfGracePeriod || dsDomainInfo?.status === DOMAIN_STATUS.DOMAIN_EXPIRED;
  const isAboutToExpire = hasDsDomain && daysDifference < 30 && daysDifference >= 0;
  return { hasExternalDomain, hasDsDomain, isOutOfGracePeriod, isExpired, isAboutToExpire };
};

export const shouldShownDomainUpsell = () => {
  const { hasExternalDomain, hasDsDomain, isOutOfGracePeriod, isExpired, isAboutToExpire } =
    getDomainSubscriptionInfo();
  return !hasExternalDomain && (!hasDsDomain || isOutOfGracePeriod || isExpired || isAboutToExpire);
};

export const getAnnouncementBarConfigByCategory = (categoryId: number) => {
  const announcementBarConfigs = store.getState().storeReducer.store.announcements;
  return (
    announcementBarConfigs?.filter(({ category }) => {
      return category === categoryId;
    }) || null
  );
};

export const updateAnnouncementBarCheckbox = (categoryId: number) => {
  const config = getAnnouncementBarConfigByCategory(categoryId);
  if (Array.isArray(config) && config.length > 0 && config[0].status === ANNOUNCMENT_BAR_CONFIG_STATUS.ACTIVE) {
    return true;
  } else {
    return false;
  }
};

export const isBrandWebsiteUpsellAllowed = () => {
  const { brand_plus_upsell_discount = 0, brand_pro_upsell_discount = 0 } =
    store.getState().storeReducer.store.constants || {};

  return !!(brand_plus_upsell_discount || brand_pro_upsell_discount);
};

export const isThemeUpsellValid = () => {
  const { premium_theme_upsell_discount = 0 } = store.getState().storeReducer.store.constants || {};
  return !!premium_theme_upsell_discount;
};

export const isTimerExpired = (hour, minute, second) => {
  return hour === "00" && minute === "00" && second === "00";
};

export const CustomisedToast = (messageType: number, message: string) => {
  if (messageType === TOAST_TYPE.FAILURE) {
    ToastNotifyError(message);
  } else if (messageType === TOAST_TYPE.SUCCESS) {
    ToastNotifySuccess(message);
  } else if (messageType === TOAST_TYPE.INFO) {
    ToastNotifyInfo(message);
  }
};
